// Image on top of a page
.wrapper {
  overflow: hidden;
}

.page-image {
  height: 355px;
  width: 100%;
  height: 40vh;
  display: block;
  background: {
    size: cover;
    position: center center;
  }
}

.page-title {
  padding: 25px 0;

  .headline {
    margin-bottom: 0;
  }

  .subheading {
    display: block;
    margin-top: 10px;
  }
}

.page-section {
  padding: 100px 0;

  &--side-image {
    padding: 150px 0;

    .image {
      position: relative;
      height: 1px;
      display: block;
      display: none;

      &--left img {
        top: -175px;
        right: 100px;
      }

      &--right {
        .image__left {
          top: 5px;
          left: 100px;
        }
      }

      img {
        position: absolute;
        max-height: 750px; // TODO: Find perfect values
      }

    }
  }
}

.background-light {
  background: $color-light;

  .collection {
    .product {
      border-color: $color-light;
    }
  }
}

.text-block {
  padding: 0 15px;

  h1 {
    margin-bottom: 50px;
  }
  h2 { color: $color-primary; }

  p.subheading {
    font-size: 24px;
    line-height: 33px;
  }

  p {
    margin-bottom: 20px;
  }

  &--center {
    text-align: center;
  }
}


.hide-mobile { display: none; }

@media screen and ( min-width: $bp-medium ) {
  .page-title {
    padding: 75px 0 35px 0;
  }
  .page-image{
    display: block;
  }

  .hide-mobile { display: block; }
  .hide-desktop { display: none; }
  .page-section {
    &--side-image {
      padding-right: 40px;
      padding-left: 40px;
      .image {
        display: block;
      }
    }
  }
}
