.shop-background {
  position: absolute;
  height: 50vh;
  width: 100%;
  z-index: -1;
  opacity: .2;
  filter: blur(20px);
  display: none;
}

.shop-product{
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  &__section {
    margin-bottom: 30px;

    &--details {
      span {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  &__preview {
    margin-bottom: 30px;

    img { max-width: 100%; }
  }

  &__order {
    flex: 1;
  }

  &__name {
    font-size: 2.6em;
    line-height: 1em;
    margin-bottom: 10px;
  }

  &__information {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;

    form { width: 100%; }

    .size-selector{
      max-width: 225px;
    }

    button[type="submit"] {
      flex: 0 1 auto;
      width: 100%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__price {
    font-size: 1.4em;
    color: $color-cta;
  }

  &__taxes {
    font-size: .9em;
    color: lighten($color-black, 20);
  }

  &__description {
    color: lighten($color-black, 20);
  }
}

.size-selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;


  label {
    display: block;
    font-size: 1em;
    margin-bottom: 10px;
  }

  select {
    border: 1px solid $color-border;
    height: 50px;
    padding-left: 10px;
    font-size: 1em;
    position: relative;
  }


}

@media screen and ( min-width: $bp-medium ) {
  .shop-background {
    display: block;
  }

  .shop-product {
    flex-direction: row;
    padding: 0 20px;

    button[type=submit] {
      display: inline-block;
      width: auto;
    }

    &__preview {
      flex: 0 0 40%;
      max-width: 40%;
      margin-right: 50px;
      margin-bottom: 0;
    }

    &__order {
      padding-left: 30px;
    }
  }
}


@media screen and (min-width: $bp-large) {
  .shop-product {
    margin-bottom: 75px;
    &__preview {
      flex: 0 0 60%;
      max-width: 60%;
    }

    .size-selector {
      margin-bottom: 40px;
    }
  }

}

@media all and (-ms-high-contrast: none) {
  .shop-background {
    display: none;
  }
}
