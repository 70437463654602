.collection {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px 25px;

  .product {
    display: inline-flex;
    flex-direction: column;
    font-size: 14px;
    margin: 10px 10px 30px 10px;
    border: 3px solid white;

    &:hover {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);

      img { border-radius: 3px; }
    }

    &__image{
      margin-bottom: 10px;

      img {
        width: 100%;
        vertical-align: bottom;
      }
    }

    &__price {
      font-weight: 500;
    }

    &__name {
      font-weight: 700;
    }

    &__info {
      display: flex;
      flex-direction: row;
      padding: 10px;

      span:last-child{
        margin-left: auto;
      }
    }
  }
}

.collection-footer {
  padding: 25px 0;
  text-align: center;

  .button {
    width: 100%;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 21px;

    &:last-child{
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}


@media screen and ( min-width: 480px ) {

  .collection {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    padding: 0;

    .product {
      width: calc(50% - 20px);
    }
  }
  .collection-footer {
    padding: 75px 0;

    .button {
      width: calc(50% - 20px);
      margin-right: 20px;
      margin-bottom: 0;
      font-size: 21px;
    }
  }
}

@media screen and (min-width: $bp-large) {
  .collection-footer {
    .button {
      width: calc(25% - 20px);
    }
  }
}
