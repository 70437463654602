.page-section.page-section--collage {
  position: relative;

  .image__right {
    right: 0;
    position: absolute;
    top: -50px;
    z-index: 5;
    display: none;
  }
}

.oskar-about{
  img {
    vertical-align: top;
    padding-right: 50px;
    padding-left: 50px;
    width: 350px;
  }
}

.profile {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  z-index: 1;

  &:last-child { margin-bottom: 0; }

  .profile-pic-container {
    max-width: 170px;
    margin: 0 15px;
    align-self: center;
    position: relative;
    margin-bottom: 20px;

    &:after {
      position: absolute;
      display: none;
      content: '';
      z-index: -1;
    }

    &.slurf-m {
      &:after {
        width: 226px;
        height: 220px;
        background: url('/v2/img/creators/slurf-m.png');
        top: -50%;
        left: -100px;
      }
    }

    &.slurf-m-2 {
      &:after {
        width: 198px;
        height: 121px;
        background: url('/v2/img/creators/slurf-m-2.png');
        top: -50px;
        right: -70%;
      }
    }

    &.slurf-c {
      &:after {
        width: 145px;
        height: 274px;
        background: url('/v2/img/creators/slurf-c.png');
        top: -50%;
        left: -30%;
      }
    }

    &.slurf-j {
      &:after {
        width: 187px;
        height: 259px;
        background: url('/v2/img/creators/slurf-j.png');
        top: -60%;
        right: -40%;
      }
    }

    img { max-width: 100%; }
  }

  &__info {
    margin: 0 25px;
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: $color-primary;
    font-family: $font;
    font-size: 24px;
    margin-bottom: 10px;
  }

  &__job {
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__description {
    font-size: 16px;
  }
}

@media screen and ( min-width: $bp-medium ) {
  .page-section.page-section--collage {
    .image__right { display: block; }
  }
  .profile {
    flex-direction: row;
    margin-bottom: 150px;
    margin-left: 12.5%;
    margin-right: 12.5%;

    &:nth-child(odd) {
      .profile-pic-container { order: 2; }
    }

    &__info {
      flex: 0 1 auto;
      width: 50%;
    }

    .profile-pic-container {
      margin-bottom: 0;
      max-width: 250px;
      min-width: 160px;

      &.slurf-c {
        &:after{
          top: -120px;
        }
      }

      &:after {
        display: inline-block;
      }
    }
  }
}
