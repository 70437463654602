.header {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  background: $color-white;
  height: $header-height; // _config.scss
  z-index: 10;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .15);

  &--fixed {
    position: fixed;
    top: 0;
    width: 100%;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      max-height: 40px;
    }
  }

  &__hamburger{
    align-self: center;
    margin-left: auto;

    button {
      background: none;
      border: 0;
    }

  }

  &__navigation {
    top: 0;
    position: absolute;
    left: 0;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;

    // Mobile
    display: none;
    height: 100vh;
    background: rgba($color-primary, .98);
    width: 100vw;
    padding: 0 5vw;

    &--active {
      display: flex;
      animation: {
        name: slideInBottom;
        duration: 256ms;
        iteration-count: 1;
        timing-function: cubic-bezier(.2, 1, .88, .91);

      }
    }

    .navigation__item {
      flex: 0 0 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      a:not(.button){ font-size: 24px }
      a.button {
        margin-top: 25px;
      }

      // Mobile
      height: 50px;
      font-size: 18px;
      text-align: center;


      &--account,
      &--cart {
        display: none;
      }

      &:last-child { border-bottom: 0; }
    }

    .navigation__close {
      position: absolute;
      top: 25px;
      right: 25px;
      font-size: 24px;
      color: white;

      button { background: none; border: 0; }
    }

    a:not(.button) {
      text-decoration: none;
      color: $color-white;
    }

    .button {
      border: 2px solid white;
      color: white;
      display: inline-block;
      flex: 0 1 auto;
      width: auto;
      height: 50px;
      line-height: 50px;
      border-radius: 25px;
    }
  }
}

.quick-nav {
  display: flex;
  flex-direction: row;
  margin-left: auto;

  &__item {
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      margin-left: 0;
    }
  }
}

.navigation__cart {
  position: relative;
}

.navigation__cart-amount {
  width: 20px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: -10px;
  background: $color-cta;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  color: $color-white;
}

// This adds the space removed from fixed on header
.ghost-header {
  height: $header-height;
  width: 100%;
  display: block;
}

// header in the book builder pages
.book-builder-header {
  position: fixed;
  padding: 20px;
  z-index: 1000;
}

@media screen and ( max-width: $bp-medium - 1px ) {
  .navigation {
    &__item {
      &--cart,
      &--account {
        margin-top: auto;
        background: $color-white;
      }
    }
  }
}

@media screen and (min-width: $bp-small) and (max-width: $bp-large) {
  .header .container { padding: 0 20px; }
}

@media screen and ( min-width: $bp-medium ) {
  .quick-nav {
    display: none;
  }
  .header {
  background: $color-white;

    &__hamburger {
      display: none;
    }

    &__navigation {
      top: auto;
      position: relative;
      margin-left: auto;
      flex-direction: row;
      height: auto;
      width: auto;
      padding: 0;
      background: $color-white;
      justify-content: flex-end;
      display: flex;

      &__cart-amount {
        right: -15px;
      }

      .navigation__close {
        display: none;
      }

      .navigation__item {
        font-size: 1em;
        border-bottom: 0;
        height: auto;
        margin-right: 30px;


        &--cart,
        &--account {
          display: flex;
        }

        &:last-child { margin-right: 0; }
      }

      a:not(.button) {
        font-size: 14px!important;
        text-decoration: none;
        color: $color-black;
      }

      .button--cta {
        color: $color-white;
        border: 1px solid $color-cta;
        transition: background 128ms ease-in-out;
        margin-top: 0!important;
        &:hover{
          background: $color-white;
          color: $color-cta;
        }

        &.button--inverted {
          color: $color-cta;

          &:hover{
            background: $color-cta;
            color: $color-white;
          }

        }
      }
    }
  }
}


@keyframes slideInBottom {
  from { transform: translateY(100%) }
  to { transform: translateY(0) }
}
