.faq-section {
  padding: 25px 0;
  border-bottom: 1px solid $color-border;

  &:first-child { padding-top: 0; }

  &--form {
    margin-bottom: 100px;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__title {
    color: $color-primary;
  }

  .faq {
    margin-bottom: 35px;

    &__question {
      font-weight: 600;
      margin-bottom: 10px;
      display: block;
    }
    &__answer {
      p {
        margin-bottom: 20px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
