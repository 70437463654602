.reset-password {
  margin: 0 auto;
  width: 100%;
  padding: 20px 0;

  .button[type=submit] {
    width: 100%;
  }
}

@media screen and (min-width: $bp-medium) {
  .reset-password {
    width: 500px;
  }
}
