// Step 4: Cover selection
.book-builder__body.book-builder__body--cover {
  max-width: 100vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cover-selection {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  text-align: center;
  position: relative;
  overflow-x: auto;
  &__cover {
    position: relative;
    z-index: 1;
    cursor: pointer;
    transition: all 128ms ease-in-out;
    flex: 0 0 60%;
    max-width: 60%;
    margin-right: 5%;
    border: 5px solid transparent;
    &:hover {
      z-index: 3;
    }
    &--selected {
      border: 5px solid $color-cta;
    }
    label {
      height: 100%;
    }
    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: bottom;
      box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .55);
    }
  }
}

@media screen and ( min-width: $bp-medium) {
  .cover-selection {
    overflow: initial;
    justify-content: center;
    padding-bottom: 50px;
    &__cover {
      top: 50px;
      flex: 0 0 20%;
      max-width: 20%;
      max-height: 95%;
      margin-right: 0;
      border: 0;
      &--selected {
        top: 0;
        z-index: 100;
        border: 0;
        img {
          box-shadow: 0 0 15px 0 rgba(0, 0, 0, .55);
          opacity: 1;
        }
      }
      &:nth-child(1) {
        transform: rotate(-2deg) translateX(50px);
      }
      &:nth-child(2) {
        transform: rotate(0deg) translateX(-10px);
      }
      &:nth-child(3) {
        transform: rotate(2deg) translateX(-100px);
      }
      &:nth-child(4) {
        transform: rotate(-5deg) translateX(-150px);
      }
    }
  }
}
