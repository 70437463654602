.order{

}

.order-overview {
  display: flex;
  flex-direction: row;
  background: $color-light;
  padding: 10px 20px;

  &__item {

    &--date {
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--status {
      margin-left: auto;

    }

    .label {
      display: inline-block;
      padding: 7px 20px;
      background: $color-light;
      border-radius: 3px;
      -webkit-font-smoothing: antialiased;
      margin-left: 10px;
      text-shadow: 0 1px rgba(0, 0, 0, .6);

      &-success {
        color: white;
        background: $color-cta;
      }

      &-pending {
        color: white;
        background: $color-primary;
      }

      &-error {
        color: white;
        background: $color-error;
      }
    }
  }
}

.order-shipping {
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  border: 5px solid $color-light;
  border-top: 0;
  margin-bottom: 50px;

  &__item{
    color: lighten($color-black, 30);

    &--track-trace {
      margin-left: auto;
    }
  }
}


.cart-footer__summary.cart-footer__summary--account {
  display: flex;
  flex-direction: row;

  .cart__summary {
    flex: 0 0 50%;
    min-width: 320px;
    max-width: 500px;
    padding: 20px;
    margin-left: auto;
    background: $color-light;

    .row {
      span:last-child { margin-left: auto; }
    }
  }
}
