// Step 1. Book builder name
.book-builder__body.book-builder__body--note,
.book-builder__body.book-builder__body--name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  width: 100%;
}
