// Step 5 Final
#preview {
  .flipbook-left-arrow,
  .flipbook-right-arrow {
    background: white!important;
    color: $color-primary!important;
    border-radius: 40px!important;
  }
}

.creation-body.creation-body--final {
  min-height: 55vh;
  max-width: 1280px;
  margin: 0 auto;
}

.creation-message {
  z-index: 10;
  position: relative;
  padding: 10px;
  height: auto;
  max-height: 20vh;
  max-width: 90vw;
  margin: 0 auto;

  h1 { font-size: 24px; }
  p { font-size: 14px; }
}

@media screen and ( min-width: $bp-medium ) {
    .creation-body.creation-body--final {
      min-height: 60vh;
    }

    .creation-message {
      height: 15vh;
      padding: 20px;
      width: 80vw;
      max-width: 600px;

      h1 { line-height: 32px; }
    }
}

@media screen and (min-width: calc($bp-large + 10)) {
  .creation-message {
    h1 {
      font-size: 32px;
      line-height: 38px;
    }
    p {
      font-size: 24px;
    }
  }
}
