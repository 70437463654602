.media {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
}

.media-nav {
  flex: 1;
  display: none;
  //display: flex;
  flex-direction: column;
  justify-content: center;

  &--prev {
  }

  &--next {

  }
}

.media-carousel {
  flex: 1;
  display: flex;
  flex-direction: row;

  &__item {
    flex: 0 1 100%;
    height: 110px;
    border: 1px solid $color-border-light;
    margin: 10px;

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    img {
      align-self: center;
      max-width: 100%;
    }
  }
}

@media screen and ( min-width: $bp-medium ) {
  .media {
    overflow-x: hidden;
  }

  .media-carousel{
    justify-content: center;
  }
}


@media all and (min-width: $bp-large) and (-ms-high-contrast: none) {
  .media {
    overflow-x: hidden;
  }
}
