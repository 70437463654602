
$form-input-spacing: 20px;

.form {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    flex-direction: column;

    &--buttons {
      flex-direction: row-reverse;
    }
    .input-wrapper {
      flex: 1;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__section {
    padding: 30px 0;

    &:first-child { padding-top: 0; }
  }

  &__footer {
    display: flex;
    flex-direction: column;

    .button {
      margin: 0 15px 15px;
      justify-content: center;
      width: 100%;

      &:first-child { margin-right: auto; margin-left: 0; }
      &:last-child { margin-left: auto; margin-right: 0; }
    }
  }

  .input-wrapper {
    position: relative;
    margin-bottom: $form-input-spacing;

    .radio {
      padding: 0;
      margin: 0;

        label {
          top: 0;
          left: 0;
          margin: 0 0 10px 0;
          padding: 0;
          position: relative;
          opacity: 1;
          color: $color-black;
          display: block;
         }
    }

    textarea,
    input[type="password"],
    input[type="text"],
    input[type="email"],
    input[type="textarea"] {
      border: 1px solid $color-border;
      height: 50px;
      width: 100%;
      padding: 0 15px;
      font-size: 16px;
      color: $color-black;
      min-width: 0;
      font-family: $font;

      &.input-filled{
        border-color: $color-border;

        +label {
          opacity: 1;
          display: block;
          color: lighten($color-black, 20);
          top: -10px;
          font-size: 12px;
          font-weight: normal;
        }
      }

      &.error {
        border-color: $color-error;
      }

      &:focus, {
        outline: none;
        border-color: $color-primary;
        border-width: 2px;

         // Show the label when focused
        + label {
          font-size: 1em;
          opacity: 1;
          display: block;
          color: $color-primary;
          top: -10px;
        }
      }
    }

    select {
      width: 100%;
      height: 50px;
      border: 1px solid $color-border;
      padding: 0 15px;
      font-size: 16px;
    }

    textarea {
      padding: 15px;
      min-height: 250px;
    }

    label.control-label {
      background: white;
      color: $color-primary;
      font-weight: 500;
      padding: 0 10px;
      height: 25px;
      line-height: 25px;
      position: absolute;
      top: 10px;
      left: 15px;
      opacity: 0;
      transition: all 256ms ease-in-out;
    }

    label.error {
      background: $color-error;
      width: 100%;
      display: block;
      padding: 10px;
      color: white;
      border-radius: 0 0 3px 3px;
      font-size: .9em;
    }

    .loader {
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -8px;
    }
  }
}

.form-errors {
  ul {
    li {
      background: $color-error;
      width: 100%;
      display: block;
      padding: 10px;
      color: white;
      border-radius: 0 0 3px 3px;
      font-size: .9em;
      line-height: 1.4em;
    }
  }
}

// Placehodler styling
::-webkit-input-placeholder{
  font-weight: 300;
}

::-moz-placeholder {
  font-weight: 300;
}

:-ms-input-placeholder {
  font-weight: 300;
}


@media screen and ( min-width: $bp-medium ) {
  .form {
    .form__row {
      flex-direction: row;

      &--buttons { flex-direction: row-reverse; }

      .input-wrapper {
        margin-right: $form-input-spacing * 1.5;
        margin-bottom: $form-input-spacing * 1.5;

        &:last-child { margin-right: 0; }
      }
    }

    .input-wrapper {
      margin-bottom: $form-input-spacing * 1.5;
    }

    &__footer {
      flex-direction: row;

      .button { width: auto; }
    }
  }
}
