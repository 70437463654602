// Login

.login {
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  width: 100%;

  &__fluff {
    margin-right: 150px;
    display: none;
    flex-direction: column;
    justify-content: center;

    img {
      vertical-align: bottom;
      max-width: 100%;
    }

  }

  &__form {
    width: 100%;

    .alert {
      margin-bottom: 20px;
    }

    .form {
      margin-bottom: 75px;
    }

    .button {
      margin-bottom: 10px;
    }
  }

  &__forgot-password {
    display: block;
    text-align: right;
    font-size: 14px;
  }

  &__create-account {
    display: block;
    text-align: center;
  }
}

@media screen and (min-width: $bp-medium) {
  .login {
    width: auto;
    display: inline-flex;
    &__fluff {
      flex: 0 0 calc(50% - 150px);
      max-width: calc(50% - 150px);
    }
    &__form {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &__fluff {
      display: flex;
    }
  }
}


@media all and (-ms-high-contrast: none) {
  .login {
    width: 100%;
    &__fluff { display: none; }
    &__form {
      display: block;
      width: 50%;
      margin: 0 auto;
      max-width: none;
      flex: none;
    }
  }
}
