.social {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-post {
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    background: $color-primary;
    width: 100%;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }

    &__image {
      height: 250px;
      overflow: hidden;

      img {
        max-width: 100%;
        vertical-align: bottom; }
    }

    &__body {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      padding: 35px 35px 25px 35px;
      color: white;
    }

    &__logo {
      margin-top: auto;
      text-align: center;
      padding-top: 25px;
    }
  }
}

@media screen and ( min-width: $bp-medium ) {
  .social {

    &-post{
      flex-direction: row;
      margin-bottom: 20px;

      &__image{
          -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 90% 50%, 100% 60%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 40%, 90% 50%, 100% 60%, 100% 100%, 0% 100%);

          img {
            min-height: 100%;
            vertical-align: bottom;
          }
      }

      &__body {
        p {
          font-size: 14px;
        }
      }

      &__logo {
        padding-top: 0;
      }

      &__image {
        min-width: 45%;
      }

      &--reverse {
        .social-post__image {
          order: 2;
          -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%, 0 60%, 10% 50%, 0 40%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%, 0 60%, 10% 50%, 0 40%);
        }
      }
    }
  }
}

@media screen and (min-width: $bp-large) {
  .social {
    flex-direction: row;
  }
}

@media all and (min-width: $bp-large) and (-ms-high-contrast: none) {
  .social-post__body { flex: 0 1 100%;}
}
