.footer-fluff {
  padding-top: 50px;
  text-align: center;

  img {
    vertical-align: bottom;
    max-width: 100%;
  }
}

.footer {
  padding-top: 50px;
  background: $color-footer;
}

.footer-section {
  padding: 50px 0;
  display: flex;

  &--vertical {
    flex-direction: column;
  }
  &:last-child {
    padding-bottom: 25px;
  }
}

.footer-menus {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}

.footer-menu {
  margin-bottom: 30px;

  &__heading {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  &__item {
    margin-bottom: 10px;
    font-size: 14px;

    a {
      color: $color-black;
      text-decoration: none;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.footer-about {
  display: none;

  &__title {
    display: block;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }
}

.footer-score {
  text-align: center;
  padding: 25px 0;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  opacity: .8;
  padding: 20px 0 0;
  position: relative;

  .copyright {
    margin-bottom: 20px;
  }

  .footer-builtby {

    a {
      color: $color-black;
      text-decoration: none;
    }
  }
}


.footer-links {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;



  &__text,
  &__link {
    >span,
    >a {
      color: $color-black;
      text-decoration: none;
      font-size: 14px;
      padding: 0 5px;
      height: 14px;
      border-right: 1px solid $color-black;
    }

    .copyright {
      opacity: .7;
    }

    a:hover { text-decoration: underline; }
  }

  li {
    &:last-child {
      a,span {
        border-right: 0;
      }
    }
  }
}

@media screen and ( min-width: $bp-medium ) {
  .footer-about {
    display: block;
    padding-right: 20px;
    padding-left: 40px;
  }

  .footer-menus {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: row;
    text-align: left;
    padding-left: 20px;

    .footer-menu {
      margin-right: 45px;
      margin-bottom: 0;
    }
  }

  .footer-copyright {
  flex-direction: row;
  font-size: 12px;
  position: relative;
  justify-content: center;

  .copyright {
    margin-bottom: 0;
  }

  .footer-builtby {
    position: absolute;
    right: 20px;

    a {
      color: $color-black;
      text-decoration: none;
    }
  }
}
}
