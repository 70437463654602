// Gegevens

.checkout__addresses {
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed $color-border;
  .address {
    span {
      display: block;
      margin-bottom: 5px;
    }
  }
}

.address {
  margin-bottom: 30px;
  &__edit {
    color: $color-black;
    text-decoration: none;
    font-size: 14px;
    margin-top: 20px;
    display: block;
    &:hover {
      color: $color-primary;
    }
  }
}

.account-options {
  display: flex;
  flex-direction: column;
  .form-group {
    margin-bottom: 20px;
  }
  a {
    font-size: 14px;
    display: block;
  }
}


@media screen and ( min-width: $bp-medium ) {
  .checkout__addresses {
    flex-direction: row;
    .address {
      &--shipping {
        margin-left: auto;
      }
    }
  }

  .account-options {
    flex-direction: row;
    margin-left: auto;

    a { margin-left: auto; }
  }

}
