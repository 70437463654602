.payments {
  background: $color-light;
  .form__footer.form__section {
    padding-bottom: 0;
  }
}

#payment {
  .payment-option {
    border-bottom: 1px dashed $color-border;

    &:last-child {
      border-bottom: 0;
    }
  }

  .form-group {
    .radio {
      background: $color-white;
      padding: 25px;
      label {
        display: block;
        input {
          margin-right: 10px;
        }
      }
    }
  }
  .payment_method_ideal {
    padding: 0 25px 25px;
    background: white;
    transform: translateY(-1px);
    &.hide {
      display: none;
    }
    label {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
    }
    select {
      font-size: 1em;
      height: 50px;
      padding: 0 15px;
      border: 1px solid $color-border;
      width: 100%;
    }
  }
}


@media screen and (min-width: $bp-medium) {
  .payments {
    padding: 25px;
  }
}
