.modal,
.modal-background {
  &.active { display: block; }
}

.modal {
  background: $color-white;
  z-index: 100;
  width: calc(100% - 40px);
  max-width: $bp-medium - 100px;
  position: absolute;
  margin: 0 20px;

  top: 20vh;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
  display: none;

  &__title {
    padding: 30px 30px 0;
    margin-bottom: 20px;

    h1,h2,h3 { margin-bottom: 0; }
  }

  &__body {
    padding: 0 30px;
    margin-bottom: 30px;

    p { font-size: .9em; }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 30px;
    border-top: 1px solid $color-border-light;
    background: $color-light;
    border-radius: 0 0 10px 10px;

    .button {
      margin-right: 20px;
      &:last-child { margin-right: 0; }
    }
  }

  &__close {
    color: $color-white;
    background: $color-primary;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    position: absolute;
    right: -15px;
    top: -15px;
    border: none;
    cursor: pointer;
  }
}

.modal-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 50;
  background: rgba(0, 0, 0, .3);
}


@media screen and (min-width: $bp-medium) {
  .modal {
    width: 100%;
    left: 50%;
    margin-left: - ($bp-medium - 100px )/2;
  }
}
