.account-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px dashed $color-border;

  &:last-child {
    border-bottom: 0;
    margin-bottom: 50px;
  }

  &--information {

    .profile-field {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 20px;

      &__name {
        font-weight: 600;
        display: block;
        margin-bottom: 5px;
      }

      &__value {

      }
    }

  }

  &--addresses {
    justify-content: space-between;

    .account-address {
      flex: 1;
      margin-right: 20px;
      line-height: 1.4em;
      background: $color-light;
      padding: 20px;
      font-size: 1.1em;

      &:last-child {
        margin-right: 0;
      }

      &--invoice{}

      &--delivery{}
    }
  }

  &--orders {
    border-bottom: 0;
    width: 100%;
    overflow-x: auto;
    padding: 1px;
    .table {
      max-width: 100%;
    }
  }
}

.account-menu {
  padding: 20px;
  display: flex;
  flex-direction: row;
  background: $color-light;

  &__logout {
    margin-left: auto;
  }
}


@media screen and ( min-width: $bp-medium ) {
  .account-section {
    flex-direction: row;
  }

  .profile-field {
    margin-bottom: 0;
  }
}
