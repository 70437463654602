.alert {
  display: block;
  padding: 20px 0;
  line-height: 1.35;
  text-align: center;
  position: relative;

  .close {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -25px;
    height: 50px;
    width: 50px;
    border: 0 none;
    color: white;
    background: transparent;
    font-size: 2em;
    cursor: pointer;
  }

  .icon { margin-right: 10px; }

  &-danger {
    color: $color-white;
    background: $color-error;
  }

  &-info {
    background: $color-primary;
  }

  &-warning {
    background: #e67e22;
  }

  &-success {
    color: $color-white;
    background: $color-cta;
  }
}

