.checkout__delivery {
  padding: 30px 0;
  margin-bottom: 50px;
  font-size: 14px;
  ul li {
    margin-bottom: 10px;
    line-height: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
