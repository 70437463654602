// Step 2. Character selection
.genders {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  .input-group {
    height: 50px;
    line-height: 50px;
    background: $color-white;
    &:first-child {
      border-radius: 25px 0 0 25px;
    }
    &:last-child {
      border-radius: 0 25px 25px 0;
    }
    label {
      overflow: hidden;
      input {
        position: absolute;
        top: -100%;
      }
      span {
        height: 50px;
        min-width: 150px;
        display: block;
        padding: 0 20px;
        color: $color-grey;
        font-weight: 600;
        user-select: none;
        text-align: center;
        -moz-user-select: none;
      }
      input:checked+span {
        color: $color-white;
        background: $color-primary;
        border-radius: 25px;
      }
    }
  }
}


.avatars {
  display: none;
  flex-direction: row;
  max-width: 100%;
  min-height: 170px;
  overflow: auto;

  &--active {
    display: flex;
  }

  .avatar {
    padding: 0;
    vertical-align: bottom;
    background: none;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 30px 30px 0;
    border: 5px solid white;
    flex: 0 0 50%;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .25);
    &:active,
    &:focus {
      outline: none;
    }
    &--selected {
      border-color: $color-cta;
    }
    img {
      vertical-align: bottom;
      max-width: 100%;
    }
  }
}

@media screen and ( min-width: $bp-medium) {
  .avatars {
    max-width: $bp-large;
    flex-wrap: wrap;
    justify-content: center;
    .avatar {
      flex: 0 1 auto;
      img {
        max-width: 120px;
        max-height: 120px;
      }
    }
  }
}

@media screen and (min-width: $bp-large) {
  .avatars {
    padding-top: 10px;
  }
  .avatars .avatar {
    transform: scale(1);
    transition: transform 64ms ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}

// Target large landscaped devices
@media screen and ( min-width: 600px ) and (max-width: 767px) and ( min-height: 600px) and (max-height: 765px) {
  .book-creation.book-creation--character {
    .avatars {
      .avatar {
        height: 200px;
        flex: 0 0 200px;
      }
    }
  }
}

// Target small landscaped devices 🤢
@media screen and (min-width: 480px) and (max-width: 767px) and (min-height: 320px) and ( max-height: 540px ){
  .book-creation.book-creation--character {
    .genders {
      margin-bottom: 0;
    }

    .avatars {
      padding: 20px 0 0 0;
      .avatar {
        flex: 0 0 125px;
        height: 125px;
      }
    }
  }
}

@media screen and (max-height: 320px) {
  .book-creation.book-creation--character {
    .genders {
      padding-top: 10px;
      .input-group span,
      .input-group { height: 30px; line-height: 30px; }
    }

    .avatars {
      .avatar {
        flex: 0 0 100px;
        height: 100px;
      }
    }
  }
}

@media screen and (min-width: 500px ) and (max-width: 763px) and (min-height: 764px) {
  .avatars {
    .avatar {
      flex: 0 0 30%;
    }
  }
}

@media screen and ( max-height: 580px) {
  .creation-message { display: none!important; }
  .book-builder__navigatin {
    margin-top: auto;
  }
}

// IE only
@media screen and (min-width: $bp-large) and (-ms-high-contrast: none) {
  .avatars {
    margin-left: calc(50% - 512px);
  }

  label img { pointer-events: none; }
}
