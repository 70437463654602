@import 'animations';
@import 'name';
@import 'character';
@import 'message';
@import 'cover';
@import 'final';


.creation-body {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.creation-nav {
  height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

// Create a fade-in effect for every step
$steps: 'name',
'character',
'note',
'cover',
'final';

@each $step in $steps {
  .book-creation--#{$step} {
    animation: {
      name: fader;
      duration: 512ms;
      iteration-count: 1;
      transition-timing-function: ease-in-out;
    }
  }
}

.book-builder {
  $header-height: 10vh;
  width: 100vw;
  height: 100vh;
  padding-top: $header-height;
  display: flex;
  flex-direction: column;
  position: relative;
  background: {
    image: url("/v2/img/builder/builder-background.jpg");
    size: cover;
    attachment: fixed;
  }
  form {
    display: flex;
    flex-direction: column;
    height: 90vh;
  }
  .background {
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    display: none;
  }
  @each $step in $steps {
    .book-creation--#{$step} .background {
      background-image: url("/v2/img/builder/builder-overlay-#{$step}.png");
      animation: {
        name: fader;
        duration: 1s;
        iteration-count: 1;
        transition-timing-function: ease-in-out;
      }
    }
  }

  &__header {
    width: 100vw;
    background: transparent;
    height: $header-height;
    padding: 10px 10px 0;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 0;
    img {
      max-height: 40px;
    }

    .close {
      margin-left: auto;
    }
  }

  &__step-instruction {
    height: 10vh;
    text-align: center;
    font-size: 28px;
    line-height: 24px;
    color: $color-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
  }

  &__body {
    height: 65vh;
    max-width: 100%;
    justify-content: center;
    flex-direction: column;
    display: flex;
    z-index: 1;
  }

  &__navigation {
    height: 15vh;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    z-index: 1;
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 5px;
    }
    .button {
      align-self: center;
      margin-right: 10px;
      flex: 1;
      text-align: center;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and ( min-width: $bp-medium) {
  .book-builder {
    .background {
      display: block;
    }
    &__step-instruction {
      font-size: 72px;
    }
    &__body {
      margin: 0 auto;
    }
    &__navigation {
      // justify-content: center;
      .buttons {
        .button {
          flex: 0 1 auto;
          margin-right: 50px;
          width: 170px;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
  }
}

// Input element
.paper {
  $paper-padding: 30px;
  border-radius: 3px;
  background: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, .45);
  padding: $paper-padding $paper-padding 0 $paper-padding;
  position: relative;
  margin: 10px;
  max-height: 100%;
  &--note {
    height: 40vh;
  }
  &__input {
    font-family: $font-cursive;
    display: block;
    padding: 0;
    height: 50px;
    align-self: stretch;
    text-align: center;
    font-size: 64px;
    border: 0 none;
    max-width: 100%;
    margin-bottom: 10px;
    &:focus {
      outline: none;
    }
  }
  &__textarea {
    text-align: center;
    border: none;
    font-size: 24px;
    line-height: 21pt;
    font-family: $font-cursive;
    color: $color-black;
    height: calc(100% - 45px);
    resize: none;
    &:focus {
      outline: none;
    }
  }
  label.error {
    color: $color-error;
    position: relative;
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
  }
  .counters {
    margin-bottom: 10px;
    margin-top: auto;
    height: 25px;
    display: block;
    color: lighten($color-black, 40);
    text-align: right;
    span {
      font-size: 14px;
      margin-right: 30px;
    }
    span:last-child {
      margin-right: 0;
    }
    i {
      margin-right: 5px;
      color: $color-black;
    }
  }
}

.book-builder__body--character,
.book-builder__body--cover {

  .error {
    background: $color-error;
    padding: 10px 0;
    color: white;
    font-weight: normal;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1000;
    animation: {
      name: dropinTop;
      duration: 256ms;
    }
  }
}

@keyframes dropinTop {
  from{
    transform: translateY(-100%)
  }
  to {
    transform: translateY(0)
  }
}

@media screen and ( min-width: $bp-medium) {

  .book-builder {
    &__header {
      padding: 20px 20px 0;
      z-index: 5;
      a { img { max-height: 100%; } }
    }
  }
  .paper {
    padding: 75px 75px 0 75px;
    border-radius: 15px;
    margin: 0 20px;
    max-width: 100%;
    .counters {
      span {
        font-size: 1em;
      }
    }
    &__input {
      height: auto;
      font-size: 130px;
      margin-bottom: 55px;
    }
    label.error {
      color: $color-error;
      position: absolute;
      bottom: 20px;
      font-size: 1em;
      left: 0;
      right: 0;
    }
    &__textarea {
      height: calc(100% - 50px);
      font-size: 36px;
      line-height: 24pt;
    }
  }
}

.input-group.remove-checkbox {
  label {
    display: block;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    input[type=radio] {
      position: absolute;
      left: -50px;
      top: -50px;
    }
  }
}


@media screen and (min-width: $bp-large) {
  .paper {
    margin: 0 auto;
    width: 100%;
    max-width: calc(1080px - 60px);
  }
}
