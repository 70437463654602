.button {
  border: none;
  background: transparent;
  font-size: 16px;
  height: 55px;
  line-height: 55px;
  font-weight: 600;
  padding: 0 30px;
  cursor: pointer;
  vertical-align: bottom;
  margin: 0;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;

  &:disabled {
    color: #E4E4E4;
    background: $color-white;
  }

  &--small {
    height: 40px;
    line-height: 40px;
  }

  &--large {
    font-size: 30px;
    height: 90px;
    line-height: 90px;
  }

  &--cta {
    color: $color-white;
    background-color: $color-cta;
    border-radius: 27px;

    &.button--small { border-radius: 20px; }
    &.button--large { border-radius: 45px; }

    &:hover {
      background-color: $color-cta-dark;
    }

    i.fa.fa-angle-right {
      font-size: 1.6em;
      transform: translateY(3px);
      margin-left: 10px;
    }
  }

  &--inverted {
    color: $color-cta;
    border: 1px solid $color-cta;
    border-radius: 27px;
    background: transparent;

    &:hover {
      background: $color-white;
      color: $color-cta;
    }
  }

  &--primary {
    color: $color-white;
    background: $color-primary;
    -webkit-font-smoothing: antialiased;
  }

  &--cancel {
    color: $color-grey;
    border: 1px solid $color-border-light;
  }

  &--return {
    color: $color-cta;
    border: none;
    background: $color-white;
    border-radius: 30px;
  }

  &--not-rounded {
    border-radius: 0;
  }

}
