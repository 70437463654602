.checkout-navigation {
  display: flex;
  flex-direction: row;
  margin: 25px 0;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-black;
    background: $color-grey;
    text-align: center;
    margin-right: 10px;

    &:last-child { margin-right: 0; }

    &.completed {
      opacity: 1;
      background: $color-cta;
      color: $color-white;

      .checkout-navigation__step-number {
        background: transparent;
        color: $color-white;
      }

      span:last-child {
        &:after {
          content: "\f00c";
          font-family: "FontAwesome";
          position: absolute;
          right: 20px;
        }
      }

      &:hover {
        background: $color-cta-dark;
      }

      &:active {
        background: $color-cta;

        span.checkout-navigation__step-number {
          background: $color-cta;
        }
      }
    }

    &.active {
      background: $color-primary;
      color: $color-white;
      border-right-color: $color-primary;

      &:hover {
        background: $color-primary;
      }

      &:active {
        .checkout-navigation__step-number {
          background: darken($color-primary, 20)!important;
        }
      }

      .checkout-navigation__step-number {
        background: transparent;
      }

      a {
        cursor: default;
        pointer-events: none;
      }

    }

    &.disabled {
      cursor: default;
      &:not(.active) {
        opacity: .5;
      }
      &:hover {
        opacity: 0.5;
        a {
          cursor: pointer;
          pointer-events: none;
        }
      }
    }

    a {
      text-decoration: none;
      color: inherit;
      line-height: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: relative;
      transition: all 128ms ease-in-out;

      span.checkout-navigation__step-number {
        width: 50px;
        left: 0;
      }

      span:not(.checkout-navigation__step-number) {
        display: none;
      }
    }
  }
}

@media screen and ( min-width: $bp-medium ) {
  .checkout-navigation {
    &__item {

      &.completed {
        .checkout-navigation__step-number {
          background: $color-cta-dark;
        }
      }

      &.active {
        .checkout-navigation__step-number {
           background: darken($color-primary, 20)!important;
        }
      }

      a {
        span.checkout-navigation__step-number {
          width: 50px;
          position: absolute;
          left: 0;
          background: rgba(255, 255, 255, .2);
        }
        span:not(.checkout-navigation__step-number) {
          display: block;
        }
      }
    }
  }
}
