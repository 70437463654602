// Colours
$color-white: #fff;
$color-light: #FAFAFA;
$color-grey: #D5D5D5;
$color-black: #4A4A4A;

$color-border-light: #E4E4E4;
$color-border: #9B9B9B;

$color-cta: #2ADB96;
$color-cta-dark: #15BE7C;

$color-primary: #4BBFDE;
$color-secondary: #F47FA8;

$color-footer: #F7F7F7;

$color-error: #d33030;


// Grid
$grid-medium-width: 728px;
$grid-width: 968px;
$gutter-small: 20px;

  // Breakpoints
$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1024px;

// Heights
$header-height: 65px;
