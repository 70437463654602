.success {
  &__social-media {
    font-size: 1.2em;
    margin-bottom: 50px;

    a {
      color: $color-black;
      text-decoration: none;
      margin-right: 20px;
      opacity: .7;

      &:hover {
        opacity: 1;
        color: $color-primary;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.page-section--success {
  padding: 25px 0;
}

@media all and (min-width: $bp-medium) {
  .success {
    &__social-media {
      margin-bottom: 100px;
    }
  }
  .page-section--success {
    padding: 75px 0;
  }
}
