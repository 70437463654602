/* =============
  1. Hero
  2. USP
================ */

.homepage-section {
  padding: 45px 0;

  &:last-child { padding-bottom: 0; }

  .special-offer{
    margin-bottom: 50px;
  }
}

// 1. Hero
.hero {
  background: {
    image: url('/v2/img/oskar-home-mobile.jpg');
    size: cover;
    repeat: no-repeat;
    position: top left;
  }
  min-height: calc(100vh - #{$header-height}); // _config.scss
  display: flex;
  flex-direction: column;
  position: relative;

  &:after {
    content: " ";
    background: rgba(0, 0, 0, .2);

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    &:after{
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      background: rgba(0, 0, 0, .35);
    }

    video {
      min-width: 100%;
      min-height: 100%;
    }
  }

  &__body {
    color: $color-white;
    display: flex;
    flex-direction: column;
    font-size: 24px;


    width: 100%;
    max-width: 90vw;
    margin: 0 auto;
    margin-top: 10vh;
    position: relative;
    z-index: 5;
    padding-left: 10px;
    padding-right: 10px;

    h1 {
      color: $color-white;
      width: 80%;
      margin-bottom: 30px;
    }

    .hero-button {
      height: auto;
      align-self: flex-start;
      padding: 20px 45px;
      border-radius: 100px;

      span {
        display: block;
      }

      &__main {
        font-size: 34px;
        line-height: 1em;
        margin-bottom: 7px;
        letter-spacing: 0.015em;
      }

      &__cta-line {
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0.08em;
        line-height: 1em;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    padding: 25px 0;
    position: relative;
    z-index: 5;

    .scroll-down {
      width: 30px;
      margin: 0 auto;
      transition: opacity 128ms ease-in-out;

      cursor: pointer;
      animation: {
        name: jump;
        duration: 1500ms;
        iteration-count: infinite;
        timing-function: ease-in-out;
      }

      &:hover {
        animation: {
          play-state: paused;
        }
      }

      &:active {
        opacity: .2;
      }
    }

    .footer__help {
      margin-left: auto;
    }
  }
}

// 2. USP
.usps {
  margin-bottom: 75px;
  flex-direction: column;

  .usp {
    text-align: center;
    flex: 1;
    padding: 0 20px;
    margin-bottom: 50px;

    &:last-child{
      border-right: 0;
    }

    &__image{
      margin-bottom: 25px;
      height: 160px;
      position: relative;
      text-align: center;
      flex-direction: column;

      img {
        max-height: 100%;
        max-width: 100%;
        margin-top: auto;
      }
    }

    &__title{
      display: block;
      font-size: 24px;
      color: $color-primary;
      margin-bottom: 20px;
    }

  }
}

@media screen and ( max-width: 380px ) {
  .hero .hero__body h1 {
    font-size: 1em;
  }
}

@media screen and ( max-width: $bp-medium ) {

  .hero {
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .25);
    }

    &__video {
      display: none;
    }

    &__body {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h1 {
        font-size: 32px;
        line-height: 41px;
        font-weight: 400;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, .85);

        span {
          font-weight: 500;
        }
      }

      .hero-button {
        max-width: 100%;

        &__main {
          font-size: 21px;
        }

        &__cta-line{
          line-height: 1.2em;
          font-size: 12px;
          white-space: normal;
        }

      }
    }
  }
}


@media screen and ( min-width: $bp-medium ) {

  .homepage-section {
    padding: 75px 20px;
  }

  .hero {
    &__video {
      background-image: url('/v2/img/video_preload.jpg');
      background-color: #c4bbb1;
      background-size: cover;
    }
    &__body {
      margin-top: 40vh;

      h1 {
        font-size: auto;
        max-width: 800px;
      }
    }
  }

  .usps {
    flex-direction: row;
    .usp {
      border-right: 0;
      margin-bottom: 0;

      &__image {
        display: flex;

        img {
          width: auto;
          align-self: center;
        }
      }
    }
  }
}


@keyframes jump {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0);
  }
}
