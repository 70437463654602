$font: "Poppins", sans-serif;
$font-cursive: "Ventura Times Regular", cursive;

body {
  color: $color-black;
  -webkit-font-smoothing: antialiased;

  font: {
    family: $font;
    size: 16px;
  }
}

h1, h2, h3 {
  font-weight: 400;
}

h1 {
  font-size: 28px;
  color: $color-primary;
  line-height: 35px;
}

h2 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

p {
  font-size: 16px;
  line-height: 24px;

}

a {
  color: $color-primary;
}

.headline {
  width: 100%;
  padding: 0 5vw;
  display: block;
  text-align: center;
  margin-bottom: 50px;
}

.subheading {
  font-size: 18px;
  line-height: 24px;
}

strong, b,
.bold{
  font-weight: bold;
}


@media screen and ( min-width: $bp-small ) {
  h1 {
    font-size: 50px;
    line-height: 70px;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .subheading {
    font-size: 24px;
    line-height: 33px;
  }
}


@font-face {
  font-family: "Ventura Times Regular";
  src:  url('/fonts/ventura_times-webfont.woff2') format('woff2'),
        url('/fonts/ventura_times-webfont.woff') format('woff'),
        url('/fonts/ventura_times.ttf') format('ttf')
}
