@import 'navigation';
@import 'sidebar';
@import 'information';  // Step 1
@import 'delivery';     // Step 2
@import 'payment';      // Step 3
@import 'finished';     // Step 4

.checkout {
  display: flex;
  padding: 50px 0;
  flex-direction: column;

  &__form {
    .form__footer {
      .button--cta {
        order: 1;
      }
      .button--back {
        order: 2;
      }
    }
  }

    &__content {
      flex: 0 0 60%;
      margin-bottom: 50px;
    }

    &__cart {
      margin-left: auto;
    }

}

@media screen and ( min-width: $bp-medium ) and ( max-width: $bp-large ) {
  .checkout-navigation {
    .checkout-navigation__item {
      .checkout-navigation__step-number {
        width: 25px;
      }
    }
  }
  .checkout {
  .checkout__content {
    margin-right: 45px;
  }
  }
}

@media screen and ( min-width: $bp-medium) {
  .checkout .checkout__form .form__footer {
    .button--back {
      order: 1;
      padding-left: 0;
    }
  }

  .checkout-navigation{
    padding: 0 20px;
  }

  .checkout {
    flex-direction: row;
    padding-left: 20px;
    padding-right: 20px;

    &__content{
      max-width: 60%;
      margin-right: 100px;
    }

    &__title {
      padding: 0 20px;
    }
  }
}

