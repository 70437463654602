.cart__empty {
  background: $color-light;
  padding: 15px;
  margin-bottom: 50px;

  a {
    color: $color-primary;

    &:hover { text-decoration: none; }
  }
}

.cart-content {
  margin-bottom: 50px;
  background: $color-light;
  padding: 5px;

  &--account {
    padding: 10px;
    margin-bottom: 20px;

  }

  &--small {
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 20px;

    .cart-content__product {
      flex-wrap: wrap;

      .product__price,
      .product__name {
        font-size: 1em;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .product__price{
        text-align: right;
      }

      .product__details {
        font-size: .8em;
        color: lighten($color-black, 20);
        padding-top: 10px;
        order: 2;
      }

    }

  }

  &__product {
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 10px;
    border-bottom: 1px dashed $color-border;
    justify-content: space-between;
    background: white;

    &--extra {
      .product__options {
        opacity: 1!important;
      }
    }

    &:first-child { border-radius: 5px 5px 0 0 ; }

    &:last-child {
      border-radius: 0 0 5px 5px;
      border-bottom: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover {
      .product__options {
        display: flex;
        opacity: 1;
      }
    }
  }

  .product__image {
    margin-bottom: 20px;
    display: none;
    img {
      align-self: center;
      max-height: 80px;
      border-radius: 2px;
    }
  }

  .product__name {
    margin-right: auto;
    margin-bottom: 10px;
    font-size: 18px;
    text-transform: capitalize;
    order: 1;

    .product__measurements {
      text-transform: none;
    }
  }

  .product__price {
    order: 2;
    margin-bottom: 20px;
  }

  .product__options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-right: 40px;
    font-size: 14px;
    opacity: 0.3;
    order: 3;
    align-self: flex-start;

    .amount-changer{
      display: flex;
      flex-direction: row;

      &__button {
        display: flex;
        flex-direction: column;
        height: 40px;
        width: 40px;
        text-align: center;
        justify-content: center;
        background: $color-light;
        margin-bottom: 0;
        margin-right: 0;

        &:hover{
          color: $color-black;
          background: darken($color-light, 5);
        }
      }

      input {
        height: 40px;
        text-align: center;
        font-size: 1em;
      }
    }

    a {
      color: $color-black;
      text-decoration: none;
      margin-bottom: 10px;
      margin-right: 20px;
      &:hover { color: $color-cta; }
      &:last-child { margin-bottom: 0; }

      &.option--remove:hover { color: $color-error; }
    }
  }

  .product__price {
    font-weight: 500;

    &--total {
    }
  }

  .product__measurements {
    font-weight: 300;
    font-size: 16px;
    margin-top: 5px;
    color: lighten($color-black, 30);
  }
}

.cart-footer {
  border-top: 1px dashed $color-border;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 30px 0;

  &__vouchers {
    flex: 1;
    margin-bottom: 30px;

    a {
      width: 100%;
    }

    .input-group {
      display: flex;
      flex-direction: row;

      input {
        font-size: 16px;
        padding: 0 15px;
        min-width: 0;
      }
    }
  }

  &__summary {
    flex: 1;

    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;

      &.total {
        border-top: 1px solid $color-border;
        padding-top: 20px;
        font-size: 1.2em;
        font-weight: 500;
      }

      >div:last-child { margin-left: auto; }
    }

    #voucher_remove_form {
      display: inline-block;

      button {
        border: 0;
        background: transparent;
        font-size: 14px;
        text-decoration: underline;
        color: lighten($color-black, 30);
        cursor: pointer;

        &:hover{
          color: $color-primary;
        }
      }
    }

  }


}

.cart-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    width: 100%;
    margin-bottom: 15px;

    &:first-child { margin-right: auto; order: 2; }
    &:last-child { margin-left: auto; }

    &:last-child { margin-right: 0; }
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}


@media screen and ( min-width: $bp-medium ) {
  .cart-content {
    padding: 25px;

    &--small { padding: 15px; }

    &__product {
      flex-direction: row;

      .product__image {
        margin-right: 20px;
        margin-bottom: 0;
        display: block;
      }

      &--extra {
        .product__price--total {
          padding-left: 30px;
        }
      }
    }

    .product__name, .product__price {
      margin-bottom: 0;
    }

    .product__options {
      flex-direction: column;
      justify-content: center;
      order: 1;
      align-self: center;
    }

  }

  .cart-footer {
    flex-direction: row;


    &__vouchers {
      order: 0;
      margin-bottom: 0;

      a { width: auto; }
    }

    &__summary {
      margin-bottom: 0;
    }

  }

  .cart-navigation {
    flex-direction: row;

    a {
      width: auto;
      margin-right: 30px;

      &:first-child { order: 0; }
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .cart-navigation a:last-child {
    margin: auto;
  }
}
