.checkout-cart {
  background: $color-white;
  flex: 1;
  border: 1px solid $color-light;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .25);

  .cart-content__product {
    padding: 10px;
    flex-direction: row;

    .product__name,
    .product__price {
      margin-bottom: 0;
    }
  }
}

.checkout-cart__summary {
  margin-bottom: 20px;
  .row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    padding: 0 20px;
    &:not(.total) {
      span {
        color: rgba($color-black, .6);
      }
    }
    >span:last-child {
      margin-left: auto;
    }
    &.total {
      font-size: 1.1em;
      font-weight: 500;
      margin-bottom: 20px;
      margin-top: 10px;
      padding-top: 20px;
      border-top: 1px solid $color-border;
    }
  }
}


@media screen and ( min-width: $bp-medium ) {
  .checkout-cart {
    align-self: flex-start;
  }
}
