.container {
  display: flex;
  flex-direction: row;
  margin: 0 5vw;
  width: 90vw;
  max-width: $grid-width;

  &--vertical {
    flex-direction: column;
  }
}

@media screen and ( min-width: $bp-medium ) {
  .container {
    width: 100%;
    margin: 0 auto;
    max-width: calc( 100% - 60px);

    .half {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

@media screen and ( min-width: $bp-large) {
  .container {
    max-width: $grid-width;
  }
}
