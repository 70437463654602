.table {
  width: 100%;

  tr {
    border-bottom: 1px solid $color-border;\

    &:nth-child(even){
      background: $color-light;
    }
  }

  th {
    font-weight: bold;
    text-align: left;
  }

  th, td {
    padding: 10px 20px;
  }

  td {
    border-right: 1px solid $color-border;

    &:first-child { border-left: 1px solid $color-border; }
  }
}
