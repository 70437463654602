.special-offer {
  background: {
    image: url('/v2/img/achtergrond-blauw@2x.png');
    size: cover;
  }
  padding: 85px 70px;
  width: 100%;

  display: flex;
  flex-direction: row;

  &__image {
    flex: 0 0 50%;
    max-width: 50%;
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }

  &__body {
    color: $color-white;
    background: $color-secondary;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 70px;

    .elephant {
      align-self: center;// Stops image from stretching due to flex parent
      transform: translateY(-30px);
    }

    .button {
      font-size: 24px;
      align-self: center;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 45px;
    margin-bottom: 25px;
    display: block;
  }

  &__text {
    font-size: 30px;
    display: block;
    margin-bottom: 50px;
    line-height: 40px;
  }
}
